import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import './Welcome.scss';
// import introNatureVid from '../../assets/videos/Intro Nature.mp4';
// import introNatureMobile from '../../assets/videos/Intro Nature mobile.mp4';
import introNatureVidGif from '../../assets/videos/Intro Nature Gif.gif';
import introNatureMobileGif from '../../assets/videos/Intro Nature mobile Gif.gif';

export default function Welcome() {
        const useWindowWidth = () => {
            const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
    
            //custom react hook
            useEffect(() => {
                //code/functions to be done when mounted
                const handleWindowResize = () => {
                    setWindowWidth(window.innerWidth);
                }
    
                window.addEventListener('resize', handleWindowResize);
    
                //code/functions to be done when unmounted
                return () => {
                    window.removeEventListener('resize', handleWindowResize);
                };
            }, []);

            return WindowWidth;
        }

        const windowWidth = useWindowWidth();
        // const videoURL = windowWidth >= 650 ? introNatureVid : introNatureMobile;
        const gifURL = windowWidth >= 650 ? introNatureVidGif : introNatureMobileGif;

        return (
            <div className="App Welcome welcome-video">
                {/* <img src={introNature} className='bg-video' /> */}
                {/* <video autoPlay loop muted playsInline className="bg-video"  aria-label="Welcome to Dash of Dara video" aria-describedby="a repeating gif panning through an animated 3d forest with Welcome to Dash of Dara as the text in front."> */}
                    {/* <source src={videoURL} type="video/mp4" /> */}
                    <img src={gifURL} loading='lazy' className='bg-video' alt='A gif of text that state, Welcome to Dash of Dara, in front of a green jungle background' />
                    <div className='welcome-fallback'>
                        <h1>Welcome to Dash of Dara</h1>
                    </div> 
                {/* </video> */}
                <div className="content">
                    <Button variant="contained" className="enter-btn">
                        <Link to="/portfolio" className="explore-link">Enter</Link>
                    </Button>
                </div>
            </div>
        )

}
