import React, { Component } from 'react';
import About from './About/About';
import Skills from './Skills/Skills';
import Projects from '../Portfolio/Projects/Projects';
import './Home.scss';

export class Home extends Component {
    render() {
        return (
            <>
                <div className="homepage">
                    <About />
                    <Skills />
                    <br/>
                    <br/>
                    <br/>
                    <Projects />
                    <hr/>
                    <footer>
                        <h2 style={{margin: "0", textAlign: "center"}}>Let's Get Connected</h2>
                        <ul className='footer-list'>
                            <li><a href='mailto:dashofdara@gmail.com' target='_blank' rel='noreferrer'>dashofdara@gmail.com</a></li>
                            <li><a href='https://www.dashofdara.com' target='_blank' rel='noreferrer'>DashOfDara.com</a></li>
                            <li><a href='https://www.linkedin.com/in/yvonneubak/' target='_blank' rel='noreferrer'>LinkedIn</a></li>
                        </ul>
                    </footer>
                </div>
            </>
        )
    }
}

export default Home
