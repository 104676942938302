import calender from '../assets/images/CalendarUI_New.PNG';
import profileMockup from '../assets/images/ProfileUI.PNG';
import corporateUI from '../assets/images/Corporate_ss.png';
import dashOfDara from '../assets/images/Dash_ss.png';
import expenseTracker from '../assets/images/Expense_ss.png';
import sunsetToDo from '../assets/images/Sunset_ToDo.PNG';
import worldwideTai from '../assets/images/worldwideTai.png';
import git from '../assets/images/Octocat.png';
import trGraceLogo from '../assets/images/trGrace logo.png';
import novaaNet from '../assets/images/novaa.png';
import qingOracle from '../assets/images/qingOracle.png';
import theWoo from '../assets/images/TheWoo.png';
import mealPrep from '../assets/images/Meals2GO.png';

export const calenderUI = calender;
export const profileUI = profileMockup;
export const corporate = corporateUI;
export const dash = dashOfDara;
export const expense = expenseTracker;
export const sunset = sunsetToDo;
export const tai = worldwideTai;
export const github = git;
export const trGrace = trGraceLogo;
export const novaa = novaaNet;
export const qing = qingOracle;
export const woo = theWoo;
export const meals2go = mealPrep;