import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Welcome from './components/Welcome/Welcome';
import Home from './components/Home/Home';
import './App.scss';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default class App extends Component {
  componentDidMount() {
    document.title = "Yvonne Ubak"

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyD-qJteOnAIm5PysdQmRA1sa1VQZtWZT5A",
      authDomain: "it-portfolio-36970.firebaseapp.com",
      projectId: "it-portfolio-36970",
      storageBucket: "it-portfolio-36970.appspot.com",
      messagingSenderId: "921123003180",
      appId: "1:921123003180:web:0644b2614bb61be2546a89",
      measurementId: "G-RQVQQTGMGC"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }

  render() {
    return (
      <div>
        <Router>
          <Routes>
            <Route exact path="/" element={<Welcome />} />
              {/* <Welcome /> */}
            {/* </Route> */}
            <Route exact path="/portfolio" element={<Home />} />
              {/* <Projects /> */}
            {/* </Route> */}
          </Routes>
        </Router>
      </div>
    )
  }
}

