import React, { Component } from 'react';
// import { Grid } from '@mui/material';
// import { Link } from 'react-router-dom';
import profile from '../../../assets/images/grad_red.JPG';

export class About extends Component {
    render() {

        return (
            <div className="about-page pages">
                <section className="section-one section-div">
                    <img src={profile} loading='lazy' title="Portrait Shot by Troy Montes" alt="Portrait of Yvonne Ubak in a red jumpsuit shot by Troy Montes" />
                    <h1 id='title-tag'>INNOVATIVE<br/> DEVELOPER</h1>
                    <h1 id='header-name'>Yvonne <br/> Ubak</h1>
                    <a href='#about'>
                        <div className="arrow-overlay">&darr;</div>
                    </a>
                </section>
                <section className="section-onehalf section-div" id='about'>
                <div className="about-txt">
                                <h2 style={{textAlign:'center'}}>{'{'} Available for Hire {'}'}</h2>
                                <p style={{ fontSize: '1.3em' }}>
                                    <span className='fancy-letter'>I</span> am a former Database Developer who now has an active career in Full Stack, Test Automation, Application and Web Development.
                                    My approach to coding is out-of-the-box and looking for new and creative ways to provide efficient solutions.
                                    <br/><br/>I provide a different perspective into tech where people like me were never offered opportunities
                                    before,
                                    and
                                    I believe this makes me a valuable asset in bringing your visions into reality. <br/><br/>Continue scrolling to see what I do and view my projects displaying my strengths in UI design, front-end
                                    development, back-end developement, test automation development
                                    and database management.
                                </p>
                    </div>
                </section>
                <br />
            </div>
        )
    }
}

export default About
