import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';

const ImageDialog = (props) => {
    const { onClose, media, open, title, description } = props;

  const handleClose = () => {
    onClose();
  };

  const handleBooking = () => {
      //go to booking section
    onClose("https://www.dashofdara.com/#booking");
  }

  const handleContact = () => {
      //go to contact section
    onClose("https://www.dashofdara.com/#contact");
  }

    return (
        <>
            <Dialog onClose={handleClose} open={open} className='dialog-box'>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <img src={media} className='dialog-media' alt={description} width={"100%"} height={"auto"}/>
                    <p>{description}</p>
                </DialogContent>
                <DialogActions className='dialog-buttons'>
                    <Button onClick={handleBooking}>Book a Consult</Button>
                    <Button onClick={handleContact}>Contact Us</Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ImageDialog;
