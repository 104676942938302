import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ImageDialog from '../../../constants/imageDialog';

const ProjectCard = (props) => {
// export class ProjectCard extends Component {
    // render() {
        const [open, setOpen] = useState(null);

        const handleOpen = (proj) => {
            setOpen(proj);
        }

        const handleClose = (targetLink = null) => {
            setOpen(null);
            if(targetLink){
                window.location.assign(targetLink);
            }
        }
        return (
            <>
                {props.projects.map(project => (
                    <Grid item className="grid-item" sm={4} key={project.id}>
                        <Card id={project.id} className="cards" key={project.id}>
                                <img src={project.pic} alt={project.description} title={project.title} className='cardmedia' width={project.width ? project.width : "100%"} 
                                style={{maxHeight: project.height ? project.height : "400px", objectFit: "cover", margin: "auto",display: "block"}}/>
                                <CardContent>
                                    <Typography className='projectHeader'>
                                        <h3>{project.title}</h3>
                                    </Typography>
                                    <Typography varient="body2" component="p">{project.description}</Typography>
                                </CardContent>
                            <CardActions>
                                {(project.link && !project.link.includes("image")) ?
                                    <Button href={project.link} target="_blank" varient="contained" className="view-btn">
                                        VIEW
                                </Button>
                                    : (project.link && project.link.includes("image")) ?
                                    <>  
                                        <Button onClick={() => handleOpen(project)} varient="contained" className="view-btn">
                                            VIEW
                                        </Button> 
                                        {open && open.id === project.id && (
                                            <ImageDialog onClose={handleClose} 
                                                media={open.pic} 
                                                open={Boolean(open)} 
                                                title={open.title} 
                                                description={open.description} 
                                            />
                                        )}
                                    </>
                                    :<></>}
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </>
        )
    // }
}

export default ProjectCard
